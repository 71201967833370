<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiNavigationToggleProps {
  href?: string | boolean;
  open?: boolean;
  type?: 'white' | 'black';
}
const props = withDefaults(defineProps<CiNavigationToggleProps>(), {
  href: false,
  open: true,
  type: 'white',
});
</script>

<template>
  <button
    v-if="!href"
    :class="`nav-toggle--${props.type}`"
    class="relative z-10 flex h-[50px] items-center border-0 p-0 text-base font-normal hover:text-dark hover:underline"
    type="button"
    role="button"
    aria-label="Menu"
    data-cy="ci-navigation-toggle"
  >
    <svg
      v-if="open"
      :class="[props.type === 'white' ? 'fill-white' : 'fill-black']"
      width="31px"
      height="17px"
      viewBox="0 0 31 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill-rule="evenodd"
      >
        <g
          transform="translate(-51.000000, -62.000000)"
          fill-rule="nonzero"
        >
          <g transform="translate(0.000000, -20.000000)">
            <g transform="translate(50.051885, 80.464492)">
              <rect
                transform="translate(7.500000, 13.500000) rotate(35.000000) translate(-7.500000, -13.500000) "
                x="0"
                y="12"
                width="15"
                height="3"
                rx="1.5"
              />
              <rect
                x="1.9481149"
                y="8.53550757"
                width="30"
                height="3"
                rx="1.5"
              />
              <rect
                transform="translate(7.500000, 6.500000) rotate(-35.000000) translate(-7.500000, -6.500000) "
                x="0"
                y="5"
                width="15"
                height="3"
                rx="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-else
      :class="[props.type === 'white' ? 'fill-white' : 'fill-black']"
      width="30px"
      height="19px"
      viewBox="0 0 30 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill-rule="evenodd"
      >
        <g
          transform="translate(-20.000000, -62.000000)"
          fill-rule="nonzero"
        >
          <g transform="translate(10.000000, 0.000000)">
            <g transform="translate(10.000000, 62.000000)">
              <rect
                x="0"
                y="16"
                width="20"
                height="3"
                rx="1.5"
              />
              <rect
                x="0"
                y="8"
                width="30"
                height="3"
                rx="1.5"
              />
              <rect
                x="0"
                y="0"
                width="20"
                height="3"
                rx="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <slot />
  </button>
  <nuxt-link
    v-else
    :to="props.href.toString()"
    class="relative z-10 flex h-[50px] items-center border-0 p-0 text-base font-normal hover:text-dark hover:underline"
  >
    <svg
      v-if="props.open"
      :class="[props.type === 'white' ? 'fill-white' : 'fill-black']"
      width="31px"
      height="17px"
      viewBox="0 0 31 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill-rule="evenodd"
      >
        <g
          transform="translate(-51.000000, -62.000000)"
          fill-rule="nonzero"
        >
          <g transform="translate(0.000000, -20.000000)">
            <g transform="translate(50.051885, 80.464492)">
              <rect
                transform="translate(7.500000, 13.500000) rotate(35.000000) translate(-7.500000, -13.500000) "
                x="0"
                y="12"
                width="15"
                height="3"
                rx="1.5"
              />
              <rect
                x="1.9481149"
                y="8.53550757"
                width="30"
                height="3"
                rx="1.5"
              />
              <rect
                transform="translate(7.500000, 6.500000) rotate(-35.000000) translate(-7.500000, -6.500000) "
                x="0"
                y="5"
                width="15"
                height="3"
                rx="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-else
      :class="[props.type === 'white' ? 'fill-white' : 'fill-black']"
      width="30px"
      height="19px"
      viewBox="0 0 30 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill-rule="evenodd"
      >
        <g
          transform="translate(-20.000000, -62.000000)"
          fill-rule="nonzero"
        >
          <g transform="translate(10.000000, 0.000000)">
            <g transform="translate(10.000000, 62.000000)">
              <rect
                x="0"
                y="16"
                width="20"
                height="3"
                rx="1.5"
              />
              <rect
                x="0"
                y="8"
                width="30"
                height="3"
                rx="1.5"
              />
              <rect
                x="0"
                y="0"
                width="20"
                height="3"
                rx="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <slot />
  </nuxt-link>
</template>

<style></style>
